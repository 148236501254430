import classNames from 'classnames'

import { menuTree } from 'components/Header'
import { Logo } from 'components/Icons'
import { NavLink } from 'components/Navigation/NavLink'
import useStore from 'store'

export function getIsActive(url: string, page: string) {
  const segments = url.split('/')
  return segments.includes(page)
}

export default function Navigation() {
  const focusComponent = useStore((s) => s.focusComponent)

  return (
    <div
      className={classNames(
        focusComponent ? 'absolute left-4 top-3 z-1 block' : 'flex flex-1 items-center',
      )}
    >
      <NavLink isHome item={menuTree[0]}>
        <span className='block w-10 h-10'>
          <Logo className='text-white' />
        </span>
      </NavLink>
      {!focusComponent && (
        <div className='flex gap-8 px-6'>
          {menuTree.map((item, index) => (
            <NavLink key={index} item={item}>
              {item.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  )
}
