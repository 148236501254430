import useSWR from 'swr'

import getPrices from 'api/price/getPrices'

export default function usePrice() {
  return useSWR('queryPrices', () => getPrices(), {
    fallbackData: [],
    refreshInterval: 30_000,
    revalidateOnFocus: false,
  })
}
