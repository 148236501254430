import { useCallback, useMemo } from 'react'

import AssetImage from 'components/Asset/AssetImage'
import Button from 'components/Button'
import { ArrowCircle, Enter } from 'components/Icons'
import Modal from 'components/Modal'
import SettingsOptions from 'components/Modals/Settings/SettingsOptions'
import SettingsSwitch from 'components/Modals/Settings/SettingsSwitch'
import Select from 'components/Select'
import Text from 'components/Text'
import { DEFAULT_SETTINGS } from 'constants/defaultSettings'
import { DISPLAY_CURRENCY_KEY, REDUCE_MOTION_KEY, TUTORIAL_KEY } from 'constants/localStore'
import useAlertDialog from 'hooks/useAlertDialog'
import useLocalStorage from 'hooks/useLocalStorage'
import useStore from 'store'
import { getDisplayCurrencies } from 'utils/assets'

export default function SettingsModal() {
  const modal = useStore((s) => s.settingsModal)
  const { open: showResetDialog } = useAlertDialog()
  const displayCurrencies = getDisplayCurrencies()
  const [displayCurrency, setDisplayCurrency] = useLocalStorage<string>(
    DISPLAY_CURRENCY_KEY,
    DEFAULT_SETTINGS.displayCurrency,
  )

  const [reduceMotion, setReduceMotion] = useLocalStorage<boolean>(
    REDUCE_MOTION_KEY,
    DEFAULT_SETTINGS.reduceMotion,
  )
  const [tutorial, setTutorial] = useLocalStorage<boolean>(TUTORIAL_KEY, DEFAULT_SETTINGS.tutorial)

  const displayCurrenciesOptions = useMemo(
    () =>
      displayCurrencies.map((asset, index) => ({
        label: (
          <div className='flex w-full gap-2' key={index}>
            {asset.denom === 'usd' ? (
              <Text size='sm' className='w-4 h-4 leading-4 text-center'>
                {asset.symbol}
              </Text>
            ) : (
              <AssetImage asset={asset} size={16} />
            )}
            <Text size='sm' className='leading-4'>
              {asset.name}
            </Text>
          </div>
        ),
        value: asset.denom,
      })),
    [displayCurrencies],
  )

  const handleReduceMotion = useCallback(
    (value: boolean) => {
      setReduceMotion(value)
    },
    [setReduceMotion],
  )

  const handleTutorial = useCallback(
    (value: boolean) => {
      setTutorial(value)
    },
    [setTutorial],
  )

  const handleDisplayCurrency = useCallback(
    (value: string) => {
      setDisplayCurrency(value)
    },
    [setDisplayCurrency],
  )

  const handleResetSettings = useCallback(() => {
    handleDisplayCurrency(DEFAULT_SETTINGS.displayCurrency)
    handleReduceMotion(DEFAULT_SETTINGS.reduceMotion)
  }, [handleDisplayCurrency, handleReduceMotion])

  const showResetModal = useCallback(() => {
    showResetDialog({
      icon: (
        <div className='flex w-full h-full p-3'>
          <ArrowCircle />
        </div>
      ),
      title: 'Are you sure you want to restore to default?',
      description:
        'Once you reset to default settings you can’t revert it, and will result in the permanent loss of your current settings',
      positiveButton: {
        text: 'Yes',
        icon: <Enter />,
        onClick: handleResetSettings,
      },
    })
  }, [showResetDialog, handleResetSettings])

  const handleCloseModal = useCallback(() => {
    useStore.setState({ settingsModal: false })
  }, [])

  if (!modal) return null

  return (
    <Modal
      onClose={handleCloseModal}
      header={
        <span className='flex flex-wrap items-center'>
          <Text size='3xl' className='w-full pb-1'>
            Global Settings
          </Text>
          <Text size='sm' className='text-white/50'>
            Customise to match your workflow
          </Text>
        </span>
      }
      headerClassName='p-6'
      contentClassName='flex flex-wrap px-6 pb-6 pt-4'
    >
      <SettingsSwitch
        onChange={handleReduceMotion}
        name='reduceMotion'
        value={reduceMotion}
        label='Reduce Motion'
        description='Turns off all animations inside the dApp. Turning animations off can increase the
        overall performance on lower-end hardware.'
        withStatus
      />
      <SettingsSwitch
        onChange={handleTutorial}
        name='tutoruial'
        value={tutorial}
        label='Tutorial'
        description={
          <Text size='xs' className='text-white/50'>
            Show tutorial elements in the UI.
          </Text>
        }
        withStatus
      />
      <SettingsOptions
        label='Preferred asset'
        description='By selecting a different asset you always have the trading pair or asset selector
        pre-filled with this asset.'
        className='pb-6'
      >
        <Select
          label='Display Currency'
          options={displayCurrenciesOptions}
          defaultValue={displayCurrency}
          onChange={handleDisplayCurrency}
          className='relative max-w-full border w-60 rounded-base border-white/10'
          containerClassName='justify-end'
        />
      </SettingsOptions>

      <div className='flex justify-between w-full'>
        <Button
          color='quaternary'
          variant='transparent'
          onClick={showResetModal}
          leftIcon={<ArrowCircle />}
          text='Reset to default settings'
        />
        <Button text='Confirm' onClick={handleCloseModal} />
      </div>
    </Modal>
  )
}
