import classNames from 'classnames'
import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { getIsActive } from 'components/Navigation'
import useStore from 'store'
import { getRoute } from 'utils/route'

interface Props {
  children: string | ReactNode
  item: MenuTreeEntry
  isHome?: boolean
}

export const NavLink = (props: Props) => {
  const { item, isHome } = props
  const address = useStore((s) => s.address)
  const itemLink = getRoute(item.page, address)
  const link = isHome ? getRoute('overview', address) : itemLink
  const location = useLocation()

  return (
    <Link
      to={link}
      className={classNames(
        'text-sm font-semibold hover:text-white active:text-white whitespace-nowrap',
        getIsActive(location.pathname, item.page)
          ? 'pointer-events-none text-white'
          : 'text-white/60',
      )}
    >
      {props.children}
    </Link>
  )
}
