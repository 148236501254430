const PAGE_METADATA = {
  main: {
    title: 'Mars Protocol Vesting',
    description: 'Query vested and unlocked $MARS tokens.',
    keywords: 'mars protocol, tokens',
  },
  overview: {
    title: 'Mars Protocol Vesting - Overview',
    description: 'Query vested and unlocked $MARS tokens.',
    keywords: 'mars protocol, tokens',
  },
  vesting: {
    title: 'Mars Protocol Vesting - Wallet',
    description: 'Query vested and unlocked $MARS tokens.',
    keywords: 'mars protocol, tokens',
  },
}

export default PAGE_METADATA
