import { Suspense, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { CircularProgress } from 'components/CircularProgress'
import FullOverlayContent from 'components/FullOverlayContent'
import useWalletBalances from 'hooks/useWalletBalances'
import useStore from 'store'
import { byDenom } from 'utils/array'
import { getBaseAsset } from 'utils/assets'
import { getPage, getRoute } from 'utils/route'

function FetchLoading() {
  return (
    <FullOverlayContent
      title='Fetching Wallet Data'
      copy='Please wait, while your wallet is beeing analyzed'
    >
      <CircularProgress size={40} />
    </FullOverlayContent>
  )
}

function Content() {
  const address = useStore((s) => s.address)
  const { address: urlAddress } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data: walletBalances, isLoading: isLoadingBalances } = useWalletBalances(address)
  const baseAsset = getBaseAsset()

  const baseBalance = useMemo(
    () => walletBalances.find(byDenom(baseAsset.denom))?.amount ?? '0',
    [walletBalances, baseAsset],
  )

  useEffect(() => {
    const page = getPage(pathname)
    if (page === 'vesting' && urlAddress && urlAddress !== address) {
      navigate(getRoute(page, urlAddress as string))
      useStore.setState({ balances: walletBalances, focusComponent: null })
      return
    }

    navigate(getRoute(getPage(pathname), address))
    useStore.setState({ balances: walletBalances, focusComponent: null })
  }, [baseBalance, navigate, pathname, address, walletBalances, urlAddress])

  if (isLoadingBalances) return <FetchLoading />
  return null
}

export default function WalletFetchBalancesAndAccounts() {
  return (
    <Suspense fallback={<FetchLoading />}>
      <Content />
    </Suspense>
  )
}
