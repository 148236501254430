import BigNumber from 'bignumber.js'

export class BNCoin {
  static fromDenom(denom: string, arg1: string): BNCoin {
    throw new Error('Method not implemented.')
  }
  public denom: string
  public amount: BigNumber

  constructor(coin: Coin) {
    this.denom = coin.denom
    this.amount = new BigNumber(coin.amount)
  }

  static fromDenomAndBigNumber(denom: string, amount: BigNumber) {
    return new BNCoin({ denom, amount: amount.toString() })
  }

  toCoin(): Coin {
    return {
      denom: this.denom,
      amount: this.amount.toString(),
    }
  }
}
