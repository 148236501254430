import { ReactNode, useCallback } from 'react'

import Button from 'components/Button'
import { Wallet } from 'components/Icons'
import WalletSelect from 'components/Wallet/WalletSelect'
import useStore from 'store'

interface Props {
  textOverride?: string | ReactNode
  disabled?: boolean
  className?: string
  color?: ButtonProps['color']
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
}

export default function WalletConnectButton(props: Props) {
  const handleClick = useCallback(() => {
    useStore.setState({ focusComponent: { component: <WalletSelect /> } })
  }, [])

  return (
    <Button
      variant={props.variant ?? 'solid'}
      color={props.color ?? 'tertiary'}
      size={props.size ?? 'sm'}
      disabled={props.disabled}
      onClick={handleClick}
      leftIcon={<Wallet />}
      className={props.className}
      text={props.textOverride ?? 'Connect Wallet'}
    />
  )
}
