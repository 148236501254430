import BigNumber from 'bignumber.js'

import { ENV } from 'constants/env'
import { BN_ONE, BN_ZERO } from 'constants/math'
import { BNCoin } from 'types/classes/BNCoin'

export default async function getPrices(): Promise<BNCoin[]> {
  const uri = new URL(ENV.TOKENS_URL)
  try {
    const prices: BNCoin[] = await fetch(uri.toString()).then(async (res) => {
      const data = (await res.json()) as AstroportAsset[]
      const usdPrices = [BNCoin.fromDenomAndBigNumber('usd', BN_ONE)] as BNCoin[]
      data.forEach((asset) => {
        if (asset.denom === ENV.MARS_DENOM_NEUTRON)
          usdPrices.push(
            BNCoin.fromDenomAndBigNumber('umars', new BigNumber(asset.priceUSD) ?? BN_ZERO),
          )
      })
      return usdPrices
    })
    return prices
  } catch (e) {
    console.error(e)
  }
  return []
}
