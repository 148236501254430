export function getRoute(page: Page, address?: string) {
  let nextUrl = ''

  if (address) {
    nextUrl += `/wallet/${address}`
  }

  nextUrl += `/${page}`

  let url = new URL(nextUrl, 'https://vesting.marsprotocol.io')

  return url.pathname + url.search
}

export function getPage(pathname: string): Page {
  const pages: Page[] = ['overview', 'vesting']
  const segments = pathname.split('/')

  const page = segments.find((segment) => pages.includes(segment as Page))

  if (page) {
    return page as Page
  }

  return 'overview' as Page
}
