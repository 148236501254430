import classNames from 'classnames'
import { Suspense } from 'react'

import Background from 'components/Background'
import { CircularProgress } from 'components/CircularProgress'
import Footer from 'components/Footer'
import FullOverlayContent from 'components/FullOverlayContent'
import Header from 'components/Header'
import ModalsContainer from 'components/Modals/ModalsContainer'
import PageMetadata from 'components/PageMetadata'
import Toaster from 'components/Toaster'
import useStore from 'store'

interface Props {
  focusComponent: FocusComponent | null
  children: React.ReactNode
}

function PageContainer(props: Props) {
  if (!props.focusComponent)
    return (
      <div className='w-full h-full mx-auto max-w-content'>
        <div className='flex flex-wrap w-full gap-6'>{props.children}</div>
      </div>
    )

  return (
    <div className='relative flex items-center justify-center w-full h-full'>
      {props.focusComponent.component}
    </div>
  )
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const focusComponent = useStore((s) => s.focusComponent)

  return (
    <>
      <PageMetadata />
      <Background />
      <Header />
      <main
        className={classNames(
          'lg:min-h-[calc(100vh-73px)]',
          'lg:mt-[73px]',
          'min-h-screen gap-6 px-4 py-6 pb-12 w-full relative',
          'flex',
          'justify-center',
          focusComponent && 'items-center',
        )}
      >
        <Suspense
          fallback={
            <div
              className={classNames(
                'lg:min-h-[calc(100vh-73px)]',
                'lg:mt-[73px]',
                'min-h-screen gap-6 px-4 py-6 pb-12 w-full relative',
                'flex',
                'justify-center',
                'items-center',
              )}
            >
              <FullOverlayContent title={'Loading Vesting Data'} copy={'Please wait...'}>
                <CircularProgress size={40} />
              </FullOverlayContent>
            </div>
          }
        >
          <PageContainer focusComponent={focusComponent}>{children}</PageContainer>
        </Suspense>
      </main>
      <Footer />
      <ModalsContainer />
      <Toaster />
    </>
  )
}
