import { AlertDialogController, SettingsModal } from 'components/Modals'

export default function ModalsContainer() {
  return (
    <>
      <SettingsModal />
      <AlertDialogController />
    </>
  )
}
