import classNames from 'classnames'

import EscButton from 'components/Button/EscButton'
import Navigation from 'components/Navigation'
import Settings from 'components/Settings'
import Wallet from 'components/Wallet'
import useStore from 'store'

export const menuTree: { page: Page; label: string }[] = [
  { page: 'overview', label: 'Home' },
  { page: 'vesting', label: 'Vesting Status' },
]

export default function Header() {
  const address = useStore((s) => s.address)
  const focusComponent = useStore((s) => s.focusComponent)

  function handleCloseFocusMode() {
    if (focusComponent && focusComponent.onClose) focusComponent.onClose()
    useStore.setState({ focusComponent: null })
  }

  return (
    <header
      className={classNames(
        'relative lg:fixed left-0 top-0 z-30 w-full',
        'before:content-[" "] before:absolute before:inset-0 before:-z-1 before:h-full before:w-full before:rounded-sm before:backdrop-blur-sticky',
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-between px-4 py-4 flex-wrap',
          focusComponent ? 'relative isolate' : 'border-b border-white/20',
        )}
      >
        <Navigation />
        {focusComponent ? (
          <div className='flex justify-between w-full'>
            <div className='flex h-5 w-13' />
            {address && <Wallet />}
            <EscButton onClick={handleCloseFocusMode} />
          </div>
        ) : (
          <div className='flex justify-end flex-grow gap-4 pt-4 md:pt-0'>
            <Wallet />
            <Settings />
          </div>
        )}
      </div>
    </header>
  )
}
