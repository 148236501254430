import Text from 'components/Text'

import packageInfo from '../../package.json'

export default function Footer() {
  return (
    <footer className='flex items-center justify-center w-full h-12 -mt-12'>
      <div className='w-full p-4 text-right'>
        <Text size='xs' className='opacity-50'>
          v{packageInfo.version}
        </Text>
      </div>
    </footer>
  )
}
